/* DO NOT EDIT OUTSIDE OF MASTER */

import moment from 'moment'

export const state = () => ({
  client: 'web',
  env: null,
  loading: false,
  errors: [],
  location: null,
  locationList: null,
  allLocations: [],
  defaultMenu: null,
  menu: null,
  order: null,
  checkoutStep: 0,
  showCart: false,
  user: null,
  token: null,
  orderHistory: null,
  redirect: null,
  presubmitting: false,
  submitting: false,
  navMenus: {},
  options: {
    appPrefix: 'kry'
  },
  icons: {},
  promo: {},
  width: 0,
  showNav: false,
  showBanner: true,
  savedPayments: false,
  accounts: [],
  selectedAccount: null,
  supports: null,
  giftcard: null
})

export const mutations = {
  setClient(state, str) {
    state.client = str
  },

  setEnv(state, str) {
    state.env = str
  },

  setLoading(state, bool) {
    state.loading = bool
  },

  setErrors(state, arr) {
    state.errors = arr
  },

  setLocation(state, obj) {
    obj.expires = moment().add(7, 'days').format('LLLL')
    state.location = obj
    localStorage.setItem(`${state.options.appPrefix}_loc`, JSON.stringify(obj))
  },

  setLocationList(state, something) {
    state.locationList = something
  },

  setAllLocations(state, arr) {
    state.allLocations = arr
  },

  setDefaultMenu(state, obj) {
    state.defaultMenu = obj
  },

  setMenu(state, obj) {
    if(obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_menu`)
    } else {
      obj.expires = moment().add(1, 'days').format('LLLL')
      localStorage.setItem(`${state.options.appPrefix}_menu`, JSON.stringify(obj))
    }
    state.menu = obj
  },

  setOrder(state, obj) {
    if(obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_order`)
    } else {
      obj.expires = moment().add(1, 'days').format('LLLL')
      localStorage.setItem(`${state.options.appPrefix}_order`, JSON.stringify(obj))
    }
    state.order = obj
  },

  setCheckoutStep(state, str) {
    state.checkoutStep = str
  },

  setHandoff(state, str) {
    state.order.handoff = str
  },

  setPreSubmitting(state, bool) {
    state.presubmitting = bool
  },

  setSubmitting(state, bool) {
    console.log('setSubmitting', bool)
    state.submitting = bool
  },

  setShowCart(state, bool) {
    state.showCart = bool
  },

  setUser(state, obj) {
    if(obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_user`)
    } else {
      localStorage.setItem(`${state.options.appPrefix}_user`, JSON.stringify(obj))
    }
    state.user = obj
  },

  setToken(state, obj) {
    if (obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_tok`)
    } else {
      localStorage.setItem(`${state.options.appPrefix}_tok`, JSON.stringify(obj))
    }
    state.token = obj
  },

  setOrderHistory(state, arr) {
    state.orderHistory = arr
  },

  setRedirect(state, str) {
    state.redirect = str
  },

  setNavMenus(state, obj) {
    state.navMenus = obj
  },

  setPromo(state, obj) {
    state.promo = obj
  },

  setShowBanner(state, bool) {
    state.showBanner = bool
  },

  setOptions(state, obj) {
    state.options = obj
  },

  setIcons(state, obj) {
    state.icons = obj
  },

  setWidth(state, int) {
    state.width = int
  },

  setShowNav(state, bool) {
    state.showNav = bool
  },

  setSavedPayments(state, bool) {
    state.savedPayments = bool
  },

  setAccounts(state, arr) {
    state.accounts = arr
  },

  setSelectedAccount(state, str) {
    state.selectedAccount = str
  },
  
  setSupports(state, obj) {
    state.supports = obj
  },

  setGiftcard(state, obj) {
    state.giftcard = obj
  }
}

const setGlobals = async ({ context, commit }) => {
  let globals = await context.$storyblok.getStories('_globals')

  console.log('globals::::::::::::: ', globals)
  let menus = {}
  globals.map(g => {
    if(g.content.component == 'menu') {
      menus[g.slug] = g.content.links
    } else if(g.content.component == 'footer-menu') {
      menus[g.slug] = g.content.links
    } else if(g.content.component == 'bottom-menu') {
      menus[g.slug] = g.content.links
    } else if(g.content.component == 'global') {
      let options = {}
      g.content.options.map(o => {
        options[o.key] = o.value == 'yes' ? true : o.value == 'no' ? false : o.value
      })
      commit('setOptions', options)
    } else if(g.content.component == 'icons') {
      let icons = {
        logo: g.content.logo
      }
      commit('setIcons', icons)
    } else if(g.content.component == 'menu-promo') {
      commit('setPromo', g.content)
    } else if(g.content.component == 'location_list') {
      commit('setAllLocations', g.content.locations)
    } else if(g.content.component == 'product_list') {
      commit('setDefaultMenu', g.content)
    }
  })
  commit('setNavMenus', menus)
  return true
}

export const actions = {
  async nuxtServerInit({ commit }, context ) {
    await setGlobals({ context, commit})
  },
  async nuxtClientInit({ commit }, context) {
    const p = this.state.options.appPrefix
    console.log('p:::::::::::::::::::::::::::::::: ', p)
    if(localStorage.getItem(`${p}_loc`)) {
        console.log('setLocation:::: ', JSON.parse(localStorage.getItem(`${p}_loc`)))
      commit('setLocation', JSON.parse(localStorage.getItem(`${p}_loc`)))
    }
    if(localStorage.getItem(`${p}_menu`)) {
      commit('setMenu', JSON.parse(localStorage.getItem(`${p}_menu`)))
    }
    if(localStorage.getItem(`${p}_order`)) {
      commit('setOrder', JSON.parse(localStorage.getItem(`${p}_order`)))
    }
    if(localStorage.getItem(`${p}_user`)) {
      commit('setUser', JSON.parse(localStorage.getItem(`${p}_user`)))
      commit('customer/setCustomerType', 'user')
    }
    if(localStorage.getItem(`${p}_tok`)) {
      commit('setToken', JSON.parse(localStorage.getItem(`${p}_tok`)))
    }
    if(process.env.NODE_ENV != 'development' && process.env.STORYBLOK_PREVIEW == 'true') {
      await setGlobals({ context, commit })
    }
  },
  async locationSearch({}, data) {
    await this.$api.findLocations(data)
  },
  async accountSignin({ commit }, data) {
    const token = await this.$api.loginUser(data)
    if(token) {
      const user = await this.$api.getUser()
      if(user) {
        commit('customer/setCustomerType', 'user')
        if(this.state.order) {
          this.$router.push('/order/checkout')
        } else {
          this.$router.push('/account')
        }
      }
    }
  },
  async accountSignup({ commit }, data) {
    data.phone = data.phone.replace(/[\(\)\-\s]/g, '')
    const newUser = await this.$api.createUser(data)
    if(newUser) {
      const user = await this.$api.getUser()
      if(user) {
        commit('customer/setCustomerType', 'user')
        if(this.state.order) {
          this.$router.push('/order/checkout')
        } else {
          this.$router.push('/account/locations')
        }
      }
    }
  },
  async accountUpdate({ commit }, data) {
    data.phone = data.phone.replace(/[\(\)\-\s]/g, '')
    const update = await this.$api.updateUser(data)
    if(update) {
      if(update.tokens) {
        commit('setToken', update.tokens)
      }
      this.$router.push('/account/settings')
    }
  },
  async accountSignout({ commit }) {
    await this.$api.logoutUser()
    commit('customer/setCustomerType', null)
    this.$router.push(this.state.options.signinPage)
  },
  async accountGuest({ commit }, data) {
    // data.phone = data.phone.replace(/[\(\)\-\s]/g, '')
    if (data.smsUpdates || data.emailUpdates) {
        await this.$api.guestSMSOptIn(data)
        // await this.$api.guestEMAILOptIn(data)
    }
    // if (data.smsUpdates && data.smsUpdates == true) {
    //     await this.$api.guestSMSOptIn(data)
    //     await this.$api.guestEMAILOptIn(data)
    // }
    commit('customer/setCustomer', data)
    if(this.state.order) {
        this.$router.push('/order/checkout')
    }
  },
  async forgotPassword({}, data) {
    await this.$api.forgotPassword(data.email)
    return true
  },
  fanFave({}, fave) {
    return this.state.options.fanFaves.split(',').indexOf(fave) > -1
  },
  updateCustomer({ commit }, data) {
    commit('customer/setCustomer', data)
  },
  updateDelivery({ commit }, data) {
    commit('customer/setDeliveryAddress', data)
  },
  updatePayment({ commit }, data) {
    commit('customer/setPayment', data)
  },
  updateRoute({ }, data) {
    this.$router.push(data)
  }
}