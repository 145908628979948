export default (context) => {
  // let STAGGERED_ROLLOUT = 'MCM001,NTN001,JCM008,CLV001,KNX015,KNXO20,MNT009,CDL001,CDR001,WRG003,NSH015,ATL047'
  let STAGGERED_ROLLOUT = process.env.STAGGERED_ROLLOUT;

  if (STAGGERED_ROLLOUT) {
    let staggered_locations_arr = STAGGERED_ROLLOUT.split(",");
    console.log(
      "context.store.state.location:::: ",
      context.store.state.location
    );

    if (staggered_locations_arr && context.store.state.location) {
      if (staggered_locations_arr.includes(context.store.state.location.name)) {
        console.log("we have a staggered location!!!!!!!!!!!!!!!!!!!");

        // Obține Client ID și Session ID
        const getGtagInfo = () => {
          return new Promise((resolve) => {
            gtag("get", "G-GTZPK267QX", "client_id", (client_id) => {
              gtag("get", "G-GTZPK267QX", "session_id", (session_id) => {
                resolve({ clientId: client_id, sessionId: session_id });
              });
            });
          });
        };

        // Redirectare pe baza ID-urilor obținute
        getGtagInfo().then(({ clientId, sessionId }) => {
          if (
            context.route &&
            context.route.path.toString().includes("/order")
          ) {
            if (context.route.path.toString().includes("/product")) {
              if (context.store.state.location) {
                console.log(
                  "store.state.location.slug::: ",
                  context.store.state.location.slug
                );
                if (context.store.state.location.slug) {
                  return context.redirect(
                    `${context.store.state.location.slug}products/${context.route.query?.id}/?stay=y&clientId=${clientId}&sessionId=${sessionId}`
                  );
                }
              }
            } else {
              if (
                context.store.state.location &&
                context.store.state.location.slug
              ) {
                return context.redirect(
                  `${context.store.state.location.slug}?stay=y&clientId=${clientId}&sessionId=${sessionId}`
                );
              } else {
                return context.redirect("/");
              }
            }
          }

          if (
            context.route &&
            context.route.path.toString().includes("/account")
          ) {
            console.log("we are in an account route");
            if (process.browser && window) {
              console.log("process.browser:::: ", process.browser);
              window.onNuxtReady(() => {
                window.$nuxt.$router.push("/");
              });
            } else {
              console.log("no process browser here");
              return context.redirect(302, "/");
            }
          }

          if (
            context.route &&
            context.route.path.toString().includes("/checkout")
          ) {
            console.log("we are in a checkout route");
            if (process.browser && window) {
              window.onNuxtReady(() => {
                window.$nuxt.$router.push("/");
              });
            } else {
              return context.redirect(302, "/");
            }
          }
        });
      } else {
        console.log("just a normal boring location!");
      }
    }
  }
};
